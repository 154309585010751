<template>
<div class="hello">
    <div class="">

        <main>

            <!-- Features end -->
            <!-- Testimonials start -->
            <section id="" class="pad-l">
                <div class="container">

                    <br>
                    <div id="parent">

                        <!--
  <span v-hotkey="keymap" v-show="show"> 
    Press `ctrl + esc` to toggle me! Hold `enter` to hide me!
  </span>
-->

                        <div id="container">
                            <div id="left">

                                <br>
                                <span style="padding-right: 8px;">Show:</span><br><br>
                                <label class="switch">
                                    <input type="checkbox" v-model=hideAnswers>
                                    <span class="slider round"></span>
                                </label>
                                <br><br>


                            </div>
                            <div id="center" v-if="this.data[index]">
                                 
                                <h2>
                                    {{this.data[index].question}}
                                </h2><br>

                                <input v-if="!this.hideAnswers" id="ui-key-input" v-model="message" @keydown="keypress" @keydown.esc="escFn" placeholder=''>
                                <input v-if="this.hideAnswers" id="ui-key-input" v-model="message" @keydown="keypress" @keydown.esc="escFn" :placeholder=this.data[this.index].placeholder>
                                <br><br>

                                <div v-for="(item, index) in history" :key="'list-' + index">

                                    <div class="past" v-if="index < 3">
                                        <div :class=item.valid>{{ item.solution }}</div><br>
                                        <div class="pastquestion">{{ item.question }}</div>
                                    </div>
                                </div>

                                <a :href="'../../test/' + $route.params.topic + '/' + $route.params.id">
                                    <div class="btn-blue full">Test</div>
                                </a>

                            </div>
                            <br>
                        </div>
                    </div>

                </div>
            </section>
            <!-- Testimonials end -->

        </main>


    </div>
</div>
</template>

<script>
//import router from '../router'
import keyboard from '../assets/js/keyboard.js'

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

export default {
    data() {
        return {
            show: true,
            message: '',
            index: 0,
            data: [],
            history: [],
            hideAnswers: true,
            subscribe: true
        }
    },
    created: function () {
        this.getSupaData();
        //this.getData();
        if (this.$route.params.id > 0) {
            var key = this.$cookie.get('key');
            if ((key === null) || (key.length < 30)) {
                //router.replace("/login");
                window.location.href = 'https://gum.co/eHhaGG';
            }
            this.subscribe = false;
        }

    },
    methods: {
        keypress(e) {
            keyboard.keypress(e);
            //console.log(this.data);
            console.log(this.data[this.index].placeholder);    
            if (keyboard.message.length >= 1) {
              this.message = keyboard.message;
            }
        },
        async getSupaData() {

            this.routeid = this.$route.params.id;

            fetch('../../../api/json/' + this.$route.params.topic + '.json')
                .then(response => response.json())
                .then(data => (this.data = data ))
                .then(response => {
                    this.answer = this.data[this.index].placeholder;
                    this.response = response;

                    //console.log(this.routeid);
                    //console.log(this.$route.params.id);
                    this.data = this.data.filter((v) => {
                       return v.level <= Number(this.$route.params.id)+1 
                    })

                });


            // this.answer = d;
        },
    },
    computed: {},
    watch: {
        'message': function (val) {
            //console.log(val + " " + preVal);
            //console.log('message = ' + this.message);
            //console.log('solution = ' + this.data[this.index].solution);

            // infinite training
            if (this.index >= this.data.length - 1) {
                this.index = 0;
                shuffleArray(this.data);
            }

            // convert to string if type if number
            if (typeof(this.data[this.index].solution) === 'number') {
                this.data[this.index].solution = this.data[this.index].solution.toString();
            }


            if (this.message === this.data[this.index].solution) {
                if (val != '')
                    this.history.unshift({
                        'solution': this.message,
                        'valid': 'true',
                        'question': this.data[this.index].question
                    });

                this.index += 1;
                this.message = '';

            } else if (this.data[this.index].solution.length == 1) {
                if (val != '')
                    this.history.unshift({
                        'solution': this.message,
                        'valid': 'false',
                        'question': this.data[this.index].question
                    });

                this.message = '';
            } else {
                // answer longer than one 
                if (this.message.length == this.data[this.index].solution.length) {
                    if (this.message !== this.data[this.index].solution) {
                        if (val != '')
                            this.history.unshift({
                                'solution': this.message,
                                'valid': 'false',
                                'question': this.data[this.index].question
                            });

                        this.message = '';
                    }
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '../assets/hero/css2.css';
@import '../assets/hero/normalize.css';
@import '../assets/hero/style.css';
@import '../assets/hero/responsive.css';

.pastquestion {
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
font-size: 24px;
}

/* left, center, right */
#container {
    width: 100%;
}

@media screen and (max-width : 1024px){
    #center {
        width: 100%;
    }

    #ui-key-input {
        width: 80%;
    }
}

@media screen and (min-width : 1024px){
    #left {
        float: left;
        width: 250px;
        text-align: right;
    }

    #right {
        float: right;
        width: 100px;
    }

    #center {
        width: 640px;
        margin: 0 auto;
    }
}




/* end left,center, right*/

.btn-blue {
    background: rgb(0, 51, 159);
    border: 1px solid rgb(0, 51, 159);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 0 0em;
    max-width: 85%;
    position: relative;
    text-decoration: none;
    width: 100%;
    padding: 4px 4px;
    border-radius: 5px;
    display: inline-block;
}

.btn-blue:hover {
    text-decoration: none;
    background: rgb(0, 51, 159);
}

.btn-green {
    background: rgb(44, 151, 75);
    border: 1px solid rgb(44, 151, 75);
   color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 0 0em;
    max-width: 85%;
    position: relative;
    text-decoration: none;
    width: 100%;
    padding: 4px 4px;
    border-radius: 5px;
    display: inline-block;
}

.btn-blue a,
.btn-green a {
    color: white;
}

/* slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* end slider */

.true {
    color: green;
}

.false {
    color: red;
}

.past {
    font-size: 28px;
    text-align: left;
    padding-top: 14px;
    position: relative;
    font-family: 'Inconsolata', 'courier';
    margin: 0 auto;
    padding-bottom: 14px;
    padding-left: 14px;
    border-radius: 6px;
    border: 1px solid gray;
    margin-bottom: 20px;
    animation: mymove 0.1s;
    z-index: 1;
}

@keyframes mymove {
    from {
        top: -32px;
    }

    to {
        top: 0px;
    }
}

@media screen and (max-width : 1024px){
    #ui-key-input {
    position: relative;
    background: white;
    padding-top: 14px;
    padding-bottom: 10px;
    padding-left: 14px;
    border-radius: 6px;
    border: 1px solid gray;
    width: 80%;

    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.42857143;
    color: #333;
    font-size: 28px;
    text-align: left;
    box-sizing: border-box;
    font-family: 'Inconsolata', 'courier';
    }
    .past {
        width: 80%;
    }
}

@media screen and (min-width : 1024px){
    #ui-key-input {
         position: relative;
    background: white;
    padding-top: 14px;
    padding-bottom: 10px;
    padding-left: 14px;
    border-radius: 6px;
    border: 1px solid gray;
    width: 84%;

    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.42857143;
    color: #333;
    font-size: 28px;
    text-align: left;
    box-sizing: border-box;
    font-family: 'Inconsolata', 'courier';
    }
    .past {
        width: 538px;
    }
}

.plan-item:nth-child(1),
.plan-item:nth-child(3) {
    border: 2px solid rgba(0, 0, 0, 0.1);
}

#pricing {
    color: black;
}

.kbc-button {
    padding: 6px;
    margin-top: 10px;
}

.btn_reset {
    background: white;
    opacity: 1;
    font-size: 1.6rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.8rem 2.6rem;
    transition: transform 100ms ease-in-out;
    border-radius: 3px;
    color: var(--text-dark);
    display: inline-block;
    text-align: center;
    border: 1px solid #dadce0;
    color: #000;
    border: 1px solid black;
    flex: 1 1 auto;
}

.btn {
    font-size: 1.125em;
    font-family: 'Roboto', sans-serif;
    letter-spacing: -0.025rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.btn:hover {
    color: white;
}

h2.subtitle {
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-size: 1.125em;
    line-height: 1.625;
    font-weight: 300;
}

.subtitle {
    color: white;
}

#parent {
    width: 100%;
    height: 400px;
    display: block;
    position: relative;
}

#editor {
    position: absolute;
    /* Added */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    border-radius: 8px;
}

.testimonial-item__avatar {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: -6.4rem;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;
    border-top: 4px solid #4704a1;
    border-left: 4px solid #4704a1;
}
</style>
