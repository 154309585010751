var keyboard = {
        keypress(e) {
            this.message = "";
            if (e.ctrlKey && e.key === ',') {
                this.message = "ctrl+,"
            }
            if (e.ctrlKey && e.key === 'ArrowUp') {
                this.message = "ctrl+UP";
            } else 
             if (e.ctrlKey && e.key === 'ArrowDown') {
                this.message = "ctrl+DOWN";
            } else 
            if (e.ctrlKey && e.key === 'ArrowLeft') {
                this.message = "ctrl+LEFT";
            } else 
             if (e.ctrlKey && e.key === 'ArrowRight') {
                this.message = "ctrl+RIGHT";
            } else if (e.shiftKey && e.key === 'ArrowUp') {
                this.message = "shift+UP";
            } else 
             if (e.shiftKey && e.key === 'ArrowDown') {
                this.message = "shift+DOWN";
            } else 
            if (e.shiftKey && e.key === 'ArrowLeft') {
                this.message = "shift+LEFT";
            } else 
             if (e.shiftKey && e.key === 'ArrowRight') {
                this.message = "shift+RIGHT";
            } 
            else if (e.key === 'ArrowUp') {
                this.message = "UP";
            } else if (e.key === 'ArrowDown') {
                this.message = 'DOWN';
            } else if (e.key === 'ArrowLeft') {
                this.message = 'LEFT';
            } else if (e.key === 'ArrowRight') {
                this.message = 'RIGHT';
            }

            if (e.key === ']' && e.ctrlKey) {
                e.preventDefault();
                this.message = "ctrl+]";
            }
            if (e.key === '[' && e.ctrlKey) {
                e.preventDefault();
                this.message = "ctrl+[";
            }
            if (e.which === 35 && e.ctrlKey) {
                this.message = "ctrl+END";
            }
            if (e.which === 36 && e.ctrlKey) {
                this.message = "ctrl+HOME";
            }
            if (e.which === 46) {
                this.message = "DEL";
            }
            if (e.shiftKey && e.which === 9) {
                this.message = "shift+TAB";
            } else if (e.which === 9) {
                this.message = "TAB";
            }
            if (e.which === 13) {
                this.message = "↵";
            }
            

            // detect ctrl+shift+character combinations
            for (var i = 0; i < 26; i++) {
                var letter = (i + 10).toString(36);
                if (e.ctrlKey && e.shiftKey) { 
                    letter = e.key;
                    letter = letter.toLowerCase();
                    e.preventDefault(); //prevent the default action (save page in this case)
                    this.message = "shift+ctrl+" + letter;
                }
            }


            // detect ctrl combinations
            for (i = 0; i < 26; i++) {
                letter = (i + 10).toString(36);
                if (e.key === letter && (e.ctrlKey || e.metaKey) && !e.shiftKey) { //filter down to Ctrl+S (as an example)
                    e.preventDefault(); //prevent the default action (save page in this case)
                    this.message = "ctrl+" + letter;
                }
            }

            // detect alt combinations
            for (i = 0; i < 26; i++) {
                letter = (i + 10).toString(36);
                if (e.key === letter && (e.altKey)) { //filter down to Alt+S (as an example)
                    e.preventDefault(); //prevent the default action (save page in this case)
                    this.message = "alt+" + letter;
                }
            }

            // alt+n keys
            for (var x = 0; x <= 9; x++) {
              if (e.key === x.toString() && e.altKey) {
                  e.preventDefault();
                  this.message = "alt+" + x.toString();
              }
            }  
            console.log('keyboard.message = ' + this.message); 
        },
}

export default keyboard;